export interface QuestionRules {
  show: RuleCond[];
  alert: Array<AlertNum | AlertDate | AlertYesNo>;
  field: string;
  range: SimpleRange | null;
  unit: string;
  choice: string[];
  table: string[];
}
export type QuestionRuleData = Partial<QuestionRules> | null;

export interface SimpleRange {
  min?: number;
  max?: number;
}

export const enum AlertRuleType {
  NumberMin = 'ngte',
  NumberMax = 'nlte',
  DateMin = 'dgte',
  DateMax = 'dlte',
  Yes = 'yes',
  No = 'no',
}

export const enum QuestionType {
  YNNN = 0x00,
  YesNo = 0x01,
  Number = 0x10,
  Date = 0x11,
  Decimal = 0x12,
  Dropdown = 0x18,
  Country = 0x19,

  Text = 0x20,
  Phone = 0x21,
  Email = 0x22,
  IMO = 0x23,
  MultilineText = 0x24,

  Upload = 0x31,
  Hatches = 0x32,
  MoorLines = 0x33,

  TableYesNo = 0x81,
  TableNumber = 0x90,
  TableDropdown = 0x98,
  TableUpload = 0xb1,
}

export interface AlertRuleDesc {
  type: AlertRuleType;
  qtype: QuestionType;
  text: string;
  icon: string;
}

export const AllAlertRules: AlertRuleDesc[] = [
  {
    type: AlertRuleType.Yes,
    qtype: QuestionType.YesNo,
    text: 'Alert when Answer is YES',
    icon: 'mdi-check',
  },
  {
    type: AlertRuleType.No,
    qtype: QuestionType.YesNo,
    text: 'Alert when Answer is NO',
    icon: 'mdi-close',
  },
  {
    type: AlertRuleType.NumberMin,
    qtype: QuestionType.Number,
    text: 'Alert when Number is greater than',
    icon: 'mdi-greater-than',
  },
  {
    type: AlertRuleType.NumberMax,
    qtype: QuestionType.Number,
    text: 'Alert when Number is less than',
    icon: 'mdi-less-than',
  },
  {
    type: AlertRuleType.NumberMin,
    qtype: QuestionType.Decimal,
    text: 'Alert when Number is greater than',
    icon: 'mdi-greater-than',
  },
  {
    type: AlertRuleType.NumberMax,
    qtype: QuestionType.Decimal,
    text: 'Alert when Number is less than',
    icon: 'mdi-less-than',
  },
  {
    type: AlertRuleType.DateMin,
    qtype: QuestionType.Date,
    text: 'Alert when Date is within period',
    icon: 'mdi-calendar-weekend-outline',
  },
  {
    type: AlertRuleType.DateMax,
    qtype: QuestionType.Date,
    text: 'Alert when Date is after period',
    icon: 'mdi-sort-calendar-ascending',
  },
];

export interface RuleCondDesc {
  id: 'dwt' | 'winch' | 'fuel';
  lbl: string;
  text: string;
}

export const AllRuleConds: RuleCondDesc[] = [
  {
    id: 'dwt',
    lbl: 'Conditionally alert based on DWT',
    text: 'Only show when DWT is',
  },
  {
    id: 'winch',
    lbl: 'Conditionally alert based on winch count',
    text: 'Only show when winch count is',
  },
  {
    id: 'fuel',
    lbl: 'Conditionally alert based on fuel type',
    text: 'Only show when fuel type',
  },
];

interface RuleNone {
  source: '';
}

interface RuleRange {
  source: 'dwt' | 'winch';
  low: number | null;
  high: number | null;
}

interface RuleFuel {
  source: 'fuel';
  values: string[];
}

export type RuleCond = RuleRange | RuleFuel | RuleNone;

export type AlertRules = AlertNum | AlertDate | AlertYesNo;

interface AlertRule {
  id: string;
  if: RuleCond[];
  type: AlertRuleType;
}

interface AlertNum extends AlertRule {
  type: AlertRuleType.NumberMin | AlertRuleType.NumberMax;
  val: number;
}

interface AlertDate extends AlertRule {
  type: AlertRuleType.DateMin | AlertRuleType.DateMax;
  period: string;
}

interface AlertYesNo extends AlertRule {
  type: AlertRuleType.Yes | AlertRuleType.No;
}
