
import { defineComponent, PropType } from 'vue';
import { QuestionAnswerState } from '@/vuex/AnswerStore';
import { GqlAllMyReports } from '@/gql/AllMyReports';

export default defineComponent({
  props: {
    value: {},
    qa: { type: Object as PropType<QuestionAnswerState> },
    report: { type: Object as PropType<GqlAllMyReports> },
    index: { type: String },
  },
  computed: {
    valid() {
      if (this.index) {
        return (
          this.qa.valid.errorList.find((x) => x[0] === this.index)?.[1] || ''
        );
      } else {
        return this.qa.valid.errorList[0];
      }
    },
  },
  methods: {
    updateValue(e) {
      if (this.index) {
        const jsonValue = this.value;

        jsonValue[this.index] = e.target.value;

        this.$emit('updateValue', [JSON.stringify(jsonValue), true]);
      } else {
        let valid = true;
        if (e.srcElement.value === '') {
          valid = false;
        } else if (isNaN(parseInt(e.srcElement.value, 10))) {
          valid = false;
        } else {
          if (this.qa.rules.range) {
            const minRange = this.qa.rules.range.min;
            const maxRange = this.qa.rules.range.max;

            if (
              minRange !== undefined &&
              minRange !== null &&
              minRange > parseFloat(e.srcElement.value)
            ) {
              valid = false;
            }

            if (
              maxRange !== undefined &&
              maxRange !== null &&
              maxRange < parseFloat(e.srcElement.value)
            ) {
              valid = false;
            }
          }
        }

        this.$emit('updateValue', [e.srcElement.value, valid]);
      }
    },
    isAmountInput(evt) {
      if (evt.type === 'paste') {
        if (!/^\d+(\.\d{1,2})?$/.test(evt.clipboardData.getData('text'))) {
          evt.preventDefault();
        }

        return true;
      }

      const charCode = evt.which ? evt.which : evt.keyCode;
      if (
        (charCode >= 48 && charCode <= 57) || // Numbers 0-9
        charCode === 46 // period
      ) {
        return true;
      }

      evt.preventDefault();
      return false;
    },
  },
});
